<template>
  <div>
    <div class="filter-option" v-show="showFilter">
      <!-- <div class="titre-filtre-option">Filtres</div> -->
      <div class="multiSelect" v-show="showFilterMultiselect()">
        <span class="txt-selection-zone">Zones</span>
        <multiselect
          v-if="showFilterMultiselect()"
          selectLabel=""
          deselectLabel=""
          v-model="searchValue"
          tag-placeholder="Add this as new tag"
          placeholder="Recherchez une zone ..."
          label="name"
          track-by="name"
          :options="multiSelectValue.data"
          :multiple="true"
          :taggable="false"
        >
        </multiselect>
      </div>

      <span v-if="yearMin != null && yearMax != null" class="txt-selection-zone"
        >Période</span
      >
      <div v-if="yearMin != null && yearMax != null" class="range-slider">
        <div class="slider">
          <vue-slider
            ref="rangeSlider"
            v-model="value"
            @drag-end="updateInputDates"
            :min="
              parseInt(this.yearMin.split('/').pop()) < 1950
                ? 1950
                : parseInt(this.yearMin.split('/').pop())
            "
            :max="parseInt(this.yearMax.split('/').pop())"
            :enable-cross="enableCross"
            :start-animation="startAnimation"
          >
          </vue-slider>
        </div>
        <span
          v-if="yearMin != null && yearMax != null"
          class="titre-filtre-slider"
        >
          <div class="row-dates-filter">
            Du
            <input
              @change="update(0)"
              class="date-picker-filter"
              type="date"
              id="start"
              name="trip-start"
              v-model="datesValue[0]"
              :min="yearMin"
              :max="getCurrentDate()"
            />
            au
            <input
              @change="update(1)"
              class="date-picker-filter"
              type="date"
              id="start"
              name="trip-start"
              v-model="datesValue[1]"
              :min="yearMin"
              :max="getCurrentDate()"
            />
          </div>
        </span>
      </div>
      <div v-if="this.dashboardSelected.app == 'ads'">
        <span
          v-if="yearMin != null && yearMax != null"
          class="txt-selection-zone"
          >Dossiers déposés par un professionnel</span
        >
        <div class="btn-group btn-group-toggle" data-toggle="buttons">
          <label
            class="btn btn-secondary"
            :class="{ active: isPro === 'null' }"
          >
            <input
              type="radio"
              name="options"
              id="option1"
              autocomplete="off"
              v-model="isPro"
              value="null"
            />
            Tous
          </label>
          <label
            class="btn btn-secondary"
            :class="{ active: isPro === 'true' }"
          >
            <input
              type="radio"
              name="options"
              id="option2"
              autocomplete="off"
              v-model="isPro"
              value="true"
            />
            Oui
          </label>
          <label
            class="btn btn-secondary"
            :class="{ active: isPro === 'false' }"
          >
            <input
              type="radio"
              name="options"
              id="option3"
              autocomplete="off"
              v-model="isPro"
              value="false"
            />
            Non
          </label>
        </div>
      </div>
      <div
        style="display: flex; justify-content: flex-end; margin-right: 30px;"
      >
        <b-button class="btn-applique-filtre" @click="fireSlider">
          <font-awesome-icon icon="check" />
          <span v-show="!drawerStatus" class="btn-header-text"
            >Appliquer filtres</span
          >
        </b-button>
      </div>
    </div>
    <b-card class="text-center m-2" v-if="dashboard.shared_by != null && !readOnly">
      <div v-if="userConnected.id_membre != dashboard.shared_by">
        Ce tableau de bord est en consultation uniquement car vous a été partagé par
        {{ dashboard.pseudo }}
      </div>
      <div v-else>
        Vous avez partagé ce dableau de bord à tous les utilisateurs de
        {{ dosConnected.libelle }}
      </div>
    </b-card>
    <div
      v-for="grid in dashboardSelected.data"
      :key="grid.name"
      ref="general"
      class="bloc-separateur"
      style="margin: 10px"
    >
      <button
        v-b-toggle="'collapse' + grid.name"
        class="button-collapse-graphs"
        @click="getDataGraph(item)"
      >
        <div class="b-row-separateur">
          <input
            @click.stop=""
            class="input-grid-name"
            :id="'input-grid' + grid.name"
            v-model="grid.name"
            type="text"
            v-autowidth="{
              maxWidth: '960px',
              minWidth: '20px',
              comfortZone: 0,
            }"
            disabled
          />
          <input
            @click.stop=""
            class="input-grid-name"
            :id="'grid-name' + grid.name"
            type="text"
            v-autowidth="{
              maxWidth: '960px',
              minWidth: '20px',
              comfortZone: 0,
            }"
            v-model="newGridName"
            style="display: none"
          />
          <div class="row-buttons-separateur" :id="'icon' + grid.name">
            <font-awesome-icon
              icon="pen"
              class="pen-icon"
              @click.stop="
                showInput(grid, 'show-input'), (newGridName = grid.name)
              "
            />
            <font-awesome-icon
              @click.stop="upGridSep(grid, 'up')"
              icon="arrow-up"
              class="angle-up-sep"
              v-if="showArrow(grid, 'up')"
            ></font-awesome-icon>
            <font-awesome-icon
              @click.stop="upGridSep(grid, 'down')"
              icon="arrow-down"
              class="angle-down-sep"
              v-if="showArrow(grid, 'down')"
            ></font-awesome-icon>
            <font-awesome-icon
              icon="trash-alt"
              class="trash-icon"
              @click.stop="removeGrid(grid.name)"
              v-if="showArrow(grid, 'trash')"
            />
          </div>
          <div
            class="valid-input"
            style="display: none"
            :id="'buttons' + grid.name"
          >
            <b-button
              @click.stop="
                showInput(grid, 'hide-input'),
                  (grid.name = newGridName),
                  drawCharts()
              "
              >Appliquer</b-button
            >
            <b-button @click.stop="showInput(grid, 'hide-input')"
              >Anuler</b-button
            >
          </div>
        </div>
      </button>
      <b-collapse visible :id="'collapse' + grid.name">
        <grid-layout
          class="p"
          :layout.sync="grid.graphs"
          :col-num="12"
          :row-height="30"
          :is-draggable="true"
          :is-resizable="true"
          :responsive="responsive"
          :is-mirrored="false"
          :vertical-compact="true"
          :margin="[10, 10]"
          :use-css-transforms="true"
        >
          <grid-item
            v-for="item in grid.graphs"
            :key="item.i"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            @resized="refreshGraphs"
            :i="item.i"
            drag-ignore-from=".content-bloc"
          >
            <div class="bloc" v-if="item.isGraph" @click="graphClicked(item)">
              <div class="title-bloc truncate-title-bloc">
                <font-awesome-icon :icon="item.icon" /> {{ item.name }}

                <font-awesome-icon
                  icon="info-circle"
                  :id="'popover-info-' + item.i"
                />
                <b-popover
                  class="header-popover"
                  :target="'popover-info-' + item.i"
                  triggers="hover"
                  placement="bottom"
                >
                  {{ item.description }}
                </b-popover>
                <b-row class="b-row-button">
                  <font-awesome-icon
                    @click="upGraphSep(item, 'up')"
                    icon="arrow-up"
                    class="angle-up-graph"
                    v-if="showArrow(grid, 'up')"
                  ></font-awesome-icon>
                  <font-awesome-icon
                    @click="upGraphSep(item, 'down')"
                    icon="arrow-down"
                    class="angle-down-graph"
                    v-if="showArrow(grid, 'down')"
                  ></font-awesome-icon>
                  <div class="container-remove">
                    <a class="icon-remove-graph">
                      <font-awesome-icon
                        @click="removeGraph(item.i)"
                        icon="times"
                    /></a>
                  </div>
                </b-row>
              </div>

              <div v-show="loading == item.i">
                <b-spinner
                  style="width: 4.5rem; height: 4.5rem; color: #DB524A"
                  label="chargement..."
                  class="graph-spinner"
                ></b-spinner>
              </div>
              <div
                v-show="loading != item.i"
                :ref="item.i"
                class="content-bloc"
              >
                <b-table
                  v-show="item.icon == 'table'"
                  striped
                  hover
                  :items="tab[item.i]"
                  responsive
                  no-local-sorting
                  small
                ></b-table>
              </div>
            </div>
            <div class="bloc" v-if="item.isGraph == false">
              <div class="content-panneau">
                <h2 class="panneau-text">
                  {{ item.name }}
                </h2>
              </div>
              <b-row class="b-row-button-graphs">
                <font-awesome-icon
                  @click="upGraphSep(item, 'up')"
                  icon="arrow-up"
                  class="angle-up-graph"
                ></font-awesome-icon>
                <font-awesome-icon
                  @click="upGraphSep(item, 'down')"
                  icon="arrow-down"
                  class="angle-down-graph"
                ></font-awesome-icon>
                <div class="container-remove">
                  <a class="icon-remove-graph">
                    <font-awesome-icon @click="removeGraph(item.i)" icon="times"
                  /></a>
                </div>
              </b-row>
            </div>
          </grid-item>
        </grid-layout>
      </b-collapse>
    </div>
  </div>
</template>

<script>
Vue.use(VueInputAutowidth);

import axios from "axios";
import Vuex from "vuex";
import Vue from "vue";
import VueInputAutowidth from "vue-input-autowidth";
import { jwtDecode } from "jwt-decode";

export default {
  name: "Dashboard",
  data: () => ({
    charts: {
      sep1: [],
    },
    newGridName: "",
    nameInput: true,
    nbGribLayout: 0,
    tab: {},
    loading: false,
    responsive: "",
    enableCross: true,
    startAnimation: true,
    isPro: "null",
    readOnly: false,
  }),
  watch: {
    loading: {
      handler: function(val) {
        this.$nextTick(() => {
          this.refreshGraphs();
        });
      },
    },
    addedGraph: {
      handler: function(val) {
        this.$nextTick(async () => {
          if (val !== false) {
            this.drawCharts(val);
            this.setAddedGraph(false);
          }
        });
      },
    },
    showFilter: {
      handler: function(val) {
        if (val) {
          document.getElementById("popover-filter").style.backgroundColor =
            "#fc4349";
          document.getElementById("popover-filter").style.borderColor =
            "#fc4349";
          document.getElementById("popover-filter").style.color = "white";
          document.getElementById("popover-filter").onmouseout = function() {
            document.getElementById("popover-filter").style.backgroundColor =
              "#fc4349";
            document.getElementById("popover-filter").style.borderColor =
              "#fc4349";
            document.getElementById("popover-filter").style.color = "white";
          };
        } else {
          document.getElementById("popover-filter").style.backgroundColor =
            "white";
          document.getElementById("popover-filter").style.borderColor =
            "#5a6268";
          document.getElementById("popover-filter").style.color = "#5a6268";
          document.getElementById("popover-filter").onmouseover = function() {
            document.getElementById("popover-filter").style.backgroundColor =
              "#fc4349";
            document.getElementById("popover-filter").style.borderColor =
              "#fc4349";
            document.getElementById("popover-filter").style.color = "white";
          };
          document.getElementById("popover-filter").onmouseout = function() {
            document.getElementById("popover-filter").style.backgroundColor =
              "white";
            document.getElementById("popover-filter").style.borderColor =
              "#5a6268";
            document.getElementById("popover-filter").style.color = "#5a6268";
          };
        }
      },
    },
  },
  computed: {
    value: {
      get() {
        return this.yearValue;
      },
      set(value) {
        this.setYearValue(value);
      },
    },
    datesValue: {
      get() {
        return this.datesValue;
      },
      set(value) {
        this.setDatesValue(value);
      },
    },
    searchValue: {
      get() {
        return this.zoneValue;
      },
      set(value) {
        this.setZoneValue(value);
      },
    },
    dashboardSelected: {
      get() {
        return this.dashboard;
      },
      set(value) {
        this.setDashboard(value);
      },
    },

    ...Vuex.mapGetters("Dossier", ["dosConnected"]),
    ...Vuex.mapGetters("Dashboard", [
      "datesValue",
      "showModaleSeparateur",
      "showModalePanneau",
      "addedGraph",
      "dashboard",
      "dashboards",
      "countDashboard",
      "yearMax",
      "yearMin",
      "multiSelectValue",
      "zoneValue",
      "showFilter",
      "showAddElement",
      "yearValue",
      "isInterCo",
    ]),
    ...Vuex.mapGetters("Graph", ["graphs"]),
    ...Vuex.mapGetters("Authentification", ["drawerStatus", "userConnected", "tokenLectureSeule"]),
  },
  methods: {
    showFilterMultiselect() {
      if (
        this.multiSelectValue.data.length == 0 ||
        this.multiSelectValue.data.length == 1
      ) {
        return false;
      } else {
        return true;
      }
    },
    // isInterCo() {
    //   // console.log(this.multiSelectValue.msg);
    //   // this.multiSelectValue.data.forEach((element) => {
    //   //   // console.log(element.name)
    //   //   if (element.name == this.multiSelectValue.msg) {
    //   //     console.log("Commune");
    //   //     return false;
    //   //   } else {
    //   //     console.log("interco");
    //   //     return true;
    //   //   }
    //   // });
    //   // return false;
    // },
    showInput(grid, action) {
      if (action == "show-input") {
        //Montrer Input
        document.getElementById("grid-name" + grid.name).style.display =
          "block";
        //Montrer les boutons
        document.getElementById("input-grid" + grid.name).style.display =
          "none";
        document.getElementById("icon" + grid.name).style.display = "none";
        document.getElementById("buttons" + grid.name).style.display = "block";
      }
      if (action == "hide-input") {
        //Cacher input
        document.getElementById("grid-name" + grid.name).style.display = "none";
        //Cacher les boutons
        document.getElementById("input-grid" + grid.name).style.display =
          "block";
        document.getElementById("icon" + grid.name).style.display = "block";
        document.getElementById("buttons" + grid.name).style.display = "none";
      }
    },
    showArrow(grid, move) {
      if (this.dashboardSelected.data.indexOf(grid) == 0 && move == "up") {
        return false;
      }
      if (
        this.dashboardSelected.data.indexOf(grid) ==
          this.dashboardSelected.data.length - 1 &&
        move == "down"
      ) {
        return false;
      }
      if (this.dashboardSelected.data.indexOf(grid) == 0 && move == "trash") {
        return false;
      } else {
        return true;
      }
    },
    upGridSep(grid, move) {
      let index = this.dashboardSelected.data.indexOf(grid);
      if (
        move == "down" &&
        index + 1 <= this.dashboardSelected.data.length - 1
      ) {
        //Deplacement du separateur
        let tmp = this.dashboardSelected.data[index];
        this.dashboardSelected.data[index] = this.dashboardSelected.data[
          index + 1
        ];
        this.dashboardSelected.data[index + 1] = tmp;
        this.drawCharts();
      }
      if (move == "up" && index - 1 >= 0) {
        //Deplacement du separateur
        let tmp = this.dashboardSelected.data[index];
        this.dashboardSelected.data[index] = this.dashboardSelected.data[
          index - 1
        ];
        this.dashboardSelected.data[index - 1] = tmp;
        this.drawCharts();
      }
    },
    removeGrid(gridName) {
      for (let i = 0; i < this.dashboardSelected.data.length; i++) {
        if (this.dashboardSelected.data[i].name == gridName) {
          this.dashboardSelected.data.splice(i, 1);
        }
      }
    },
    upGraphSep(item, move) {
      let isMoved = false;
      for (let i = 0; i < this.dashboardSelected.data.length; i++) {
        for (
          let i2 = 0;
          i2 < this.dashboardSelected.data[i].graphs.length;
          i2++
        ) {
          if (
            this.dashboardSelected.data[i].graphs[i2].i == item.i &&
            isMoved == false
          ) {
            if (move == "up") {
              this.dashboardSelected.data[i - 1].graphs.push(item);
            }
            if (move == "down") {
              this.dashboardSelected.data[i + 1].graphs.push(item);
            }
            this.dashboardSelected.data[i].graphs.splice(i2, 1);
            this.getDataGraph(item);
            isMoved = true;
          }
        }
      }
    },
    fireSlider() {
      this.drawCharts();
    },
    updateInputDates() {
      this.setDatesValue([
        (this.datesValue[0] =
          this.value[0] +
          "-" +
          document
            .querySelectorAll(".date-picker-filter")[0]
            .value.split("-")[1] +
          "-" +
          document
            .querySelectorAll(".date-picker-filter")[0]
            .value.split("-")[2]),
        (this.datesValue[1] =
          this.value[1] +
          "-" +
          document
            .querySelectorAll(".date-picker-filter")[1]
            .value.split("-")[1] +
          "-" +
          document
            .querySelectorAll(".date-picker-filter")[1]
            .value.split("-")[2]),
      ]);
      // this.drawCharts();
      // this.datesValue[0] = this.value[0] + '-' + document.querySelectorAll('.date-picker-filter')[0].value.split('-')[1] + '-' + document.querySelectorAll('.date-picker-filter')[0].value.split('-')[2];
      // this.datesValue[1] = this.value[1] + '-' + document.querySelectorAll('.date-picker-filter')[1].value.split('-')[1] + '-' + document.querySelectorAll('.date-picker-filter')[0].value.split('-')[2];
    },
    getCurrentDate() {
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      const formattedToday = mm + "-" + dd;
      // console.log(formattedToday)
      return formattedToday;
    },
    update(i) {
      if (i == 0) {
        this.setYearValue([
          document
            .querySelectorAll(".date-picker-filter")[0]
            .value.split("-")[0],
          this.value[1],
        ]);
      }
      if (i == 1) {
        this.setYearValue([
          this.value[0],
          document
            .querySelectorAll(".date-picker-filter")[1]
            .value.split("-")[0],
        ]);
      }
      // this.drawCharts();
    },
    getDateMinYear() {
      var formatedDate = "";
      this.yearMin < 1950
        ? (formatedDate = "1950-01-01")
        : (formatedDate = this.yearMin + "01-01");
      // console.log(formatedDate);
      return formatedDate;
    },
    ...Vuex.mapActions("Dossier", ["getDosConnected"]),
    ...Vuex.mapActions("Dashboard", [
      "setShowModaleSeparateur",
      "setShowModalePanneau",
      "setAddedGraph",
      "removeGraph",
      "addGraph",
      "setDashboardById",
      "getDashboards",
      "setDashboard",
      "getAnneeMinMax",
      "getMultiSelectValue",
      "setShowFilter",
      "setShowAddElement",
      "setYearValue",
      "setZoneValue",
      "setIsInterCo",
      "setDatesValue",
    ]),
    ...Vuex.mapActions("Authentification", ["setDrawer", "getUserConnected", "setTokenLectureSeule"]),
    refreshGraphs(e) {
      this.charts.sep1.forEach((element) => {
        element.resize();
      });
    },
    addSeparateur() {
      let htmlElement = this.$refs["general"];
    },

    getDataGraph(element) {
      let token =
        this.$route.params.token != null
          ? this.$route.params.token
          : window.$cookies.get("token");
      if (element.isGraph == true) {
        let params = {};
        let zones = [];
        this.searchValue.forEach((element) => {
          zones.push(element.name);
          zones.push(element.description);
        });
        // console.log(this.searchValue.length);
        if (
          this.multiSelectValue.data.length > 0 &&
          !this.multiSelectValue.data[0].isMultiDos &&
          (this.multiSelectValue.data.length == 0 ||
            this.searchValue.length == 0) &&
          this.dashboardSelected.app == "alerte"
        ) {
          params.zones = this.multiSelectValue.msg;
          // console.log("filtre");
        } else {
          params.zones = zones;
        }

        if (this.value.length > 0) {
          // console.log(document.querySelectorAll('.date-picker-filter')[0].value)
          // console.log(document.querySelectorAll('.date-picker-filter')[1].value)
          params.min = this.datesValue[0];
          params.max = this.datesValue[1];
        }
        return new Promise((resolve) => {
          this.loading = element.i;
          axios
            .get(
              window.adresseAPI +
                "stats/graphs/generate/" +
                element.i +
                (this.tokenLectureSeule != null ? ("/" + this.tokenLectureSeule) : '') +
                "?min=" +
                params.min +
                "&max=" +
                params.max +
                "&zones=" +
                params.zones +
                "&isPro=" +
                this.isPro,
                !this.readOnly ? {
                headers: {
                  Authorization: "Bearer " + token,
                },
              } : null
            )
            .then((response) => {
              if (element.icon == "table") {
                this.tab[element.i] = response.data.data;
              } else {
                let htmlElement = this.$refs[element.i][0];

                let myChart = this.$echarts.init(htmlElement, null, {
                  renderer: "canvas",
                });
                myChart.setOption(response.data.data);
                this.charts.sep1.push(myChart);
              }
              this.loading = false;
              resolve();
            });
        });
      }
    },

    async drawCharts(id = null) {
      if (this.dashboardSelected) {
        for (let i = 0; i < this.dashboardSelected.data.length; i++) {
          for (let element of this.dashboardSelected.data[i].graphs) {
            if (
              (id != null && id == element.i) ||
              (id == null && element.i != null)
            ) {
              await this.getDataGraph(element);
            }
          }
        }
      }
    },
  },
  created() {
    window.addEventListener("resize", this.refreshGraphs);
  },
  destroyed() {
    window.removeEventListener("resize", this.refreshGraphs);
  },

  async mounted() {
    if (this.$route.params.token) {
      this.readOnly = true;
      await this.setTokenLectureSeule(this.$route.params.token);
      const decoded = jwtDecode(this.$route.params.token);
      await this.setDashboardById(decoded.data.id);
    } else {
      await this.setDashboardById(this.$route.params.id);
    }
    window.Event.$emit("before-request");
    this.showModaleSeparateur = false;
    this.showModalePanneau = false;
    this.showAddElement = false;
    await this.getDosConnected();
    
    if (!this.readOnly) {
      await this.getUserConnected();
      this.roles = this.userConnected.roles[1];
    }

    // if (this.roles != null) {
      await this.getAnneeMinMax();
      await this.getMultiSelectValue();
      this.value[0] = parseInt(this.yearMax) - 3;
      this.value[1] = parseInt(this.yearMax);
    // }



    if (this.$refs["rangeSlider"]) {
      this.$refs["rangeSlider"].setValue(this.value);
    }

    await this.setDatesValue([
      this.yearValue[0] + "-01-01",
      this.yearValue[1] + "-" + (await this.getCurrentDate()),
    ]);
    this.drawCharts();
    if (!this.dashboardSelected) {
      this.$router.push("/tableaux-de-bord");
    }
    window.Event.$emit("after-response");
  },
};
</script>
