

if (window.location.hostname == 'clicstat.clicmap.fr') {
    //DEV
    window.adresseAPI = "https://api.clicmap.fr/";
    window.clicmap = "https://www.clicmap.fr/";
}
else {
    //DEV
    window.adresseAPI = "https://api-tortank.clicmap.fr/";
    window.clicmap = "https://www.clicmap.fr/";
}